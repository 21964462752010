<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="900"
    height="800"
    :show="show"
    :showCloseButton="false"
    :preventClickOutside="true"
    :titleText="$t('bonusWindow.congratulations')"
  >
    <template>
      <div class="bonus-window slide-fwd-center">
        <div class="chip-icon-area">
          <div class="win-popup-chip-icon"></div>
        </div>

        <div class="win-amount">
          {{ getChipFormat(amount) }}
        </div>

        <div class="button-container">
          <button
            type="button"
            class="ui-button ui-button-green"
            @click="closeWindow"
          >
            {{ $t("general.getGift") }}
          </button>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import { mapGetters } from "vuex";

import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";

import BaseWindow from "./BaseWindow";
import AnimatedNumber from "@/components/Utils/AnimatedNumber";

export default {
  name: "BonusWindow",
  components: {
    BaseWindow,
    AnimatedNumber,
  },
  computed: {
    ...mapGetters(["getChipFormat"]),
  },
  data() {
    return {
      show: false,
      amount: 0,
      animate: false,
    };
  },
  methods: {
    async onAdBonusClaimed({ amount }) {
      this.$data.amount = amount;
      this.$data.animate = true;
      this.$data.show = true;
      this.$plugins.audio.play("Bonus");
    },
    closeWindow() {
      this.$data.show = false;
      this.$data.amount = 0;
      this.$data.animate = false;
    },
  },
  beforeDestroy() {
    EventBus.$off(EventConstants.AD_BONUS_CLAIMED);
  },
  created() {
    EventBus.$on(
      EventConstants.AD_BONUS_CLAIMED,
      this.onAdBonusClaimed.bind(this)
    );
  },
};
</script>

<style lang="scss" scoped>
@keyframes chipIconBg1RotateAnimation {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes chipIconBgRotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes chipIconIncomingAnimation {
  0% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
  }
}

.bonus-window {
  position: relative;
  width: 98%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: #83212d;
  border-radius: 20px;
  .chip-icon-area {
    display: flex;
    height: 50%;
    width: 100%;
    justify-content: center;
    align-items: center;
    .win-popup-chip-icon {
      width: 400px;
      height: 400px;
      background-image: url("~@/assets/images/lobby/watch-win-collect.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .win-popup-chip-icon {
    z-index: 3;
  }
}

.win-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 56px;
  height: 20%;
  text-shadow: 0 3px 1px black;
}

.button-container {
  position: relative;
  z-index: 3;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    text-transform: uppercase;
  }
}

// .slide-fwd-center {
//   -webkit-animation: slide-fwd-center 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94)
//     both;
//   animation: slide-fwd-center 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
// }
/* ----------------------------------------------
 * Generated by Animista on 2022-8-8 11:39:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-fwd-center
 * ----------------------------------------
 */
// @-webkit-keyframes slide-fwd-center {
//   0% {
//     -webkit-transform: translateZ(0);
//     transform: translateZ(0);
//   }
//   100% {
//     -webkit-transform: translateZ(160px);
//     transform: translateZ(160px);
//   }
// }
// @keyframes slide-fwd-center {
//   0% {
//     -webkit-transform: translateZ(0);
//     transform: translateZ(0);
//   }
//   100% {
//     -webkit-transform: translateZ(160px);
//     transform: translateZ(160px);
//   }
// }
</style>
